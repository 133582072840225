<template>
	<div class="error" id="section-error">
		<h1>{{error.h1}}</h1>
		<p class="mt-4" v-html="error.message"></p>
		<p v-if="withSupport">Пожалуйста,
			<router-link :to="{name:'support'}">обратитесь в администрацию</router-link> за дополнительной информацией.
		</p>
	</div>
</template>

<script>

    export default {
        name: "error",
		props: ["code"],
        data: () => ({
            errors: {
                "unknown": {
                    h1: "Что-то пошло не так",
                    message: "Извините, что-то работает, не так, как нужно. Но мы уже тоже работаем над этим и, как раз, как нужно... Вообщем, пожалуйста, попробуйте снова через какое-то время.",
                },
                "403": {
                    h1: "Доступ ограничен",
                    message: "К сожалению, для Вашего аккаунта доступ к данной странице ограничен.",
                    withSupport: true,
                },
                "404": {
                    h1: "Не найдено",
                    message: "К сожалению, заправшиваемая запись или страница не найдена.",
                },
                "500": {
                    h1: "Что-то пошло не так",
                    message: "Извините, что-то не работает, как нужно. Мы уже работаем над этим. Пожалуйста, попробуйте снова через какое-то время.",
                },
            }
        }),
        computed: {
            error() {
                return this.errors[this.code] || this.errors["unknown"];
			},
            withSupport() {
                return this.error?.withSupport;
			}
		}
    }
</script>

<style lang="scss">
	#section-error.error {
		padding: 32px;
		background-color: transparent !important;
		border: none !important;
	}
</style>