<template>
	<div class="home d-flex align-center justify-center">
		<v-container>
			<v-row class="justify-center">
				<v-col cols="12" lg="10" xl="8">
					<v-row class="justify-center">
						<v-col cols="8">
							<home-link/>
						</v-col>
						<!--<v-col cols="4">
							<home-qr/>
						</v-col>-->
					</v-row>
					<!--<v-row>
						<v-col cols="4">
							<v-card>

							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card>

							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card>

							</v-card>
						</v-col>
					</v-row>-->
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import HomeLink from "@/components/home/home-link";
	import HomeQr from "@/components/home/home-qr";
	import Config from "@/config";

	export default {
		name: "home",
		components: {
			HomeLink,
			HomeQr,
		},
		data: () => ({
			error: null,
			loading: false,
		}),
		methods: {}
	}
</script>

<style lang="scss">
	.home {
		height: 100vh;
		width: 100%;

		.v-card {
			min-height: 240px;
		}

	}
</style>