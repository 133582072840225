export default [
    {
        path: '/',
        name: 'home',
        component: () => import('../components/home'),

    },
    {
        path: '/error/:code',
        name: 'error',
        component: () => import('../components/error'),
        props: true,
    },
    {
        path: '*',
        name: 'error-unknown',
        component: () => import('../components/error'),
        props: true,
    },
];
