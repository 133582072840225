<template>
	<v-card>
		<v-card-title>
			Создать QR-код
		</v-card-title>
		<v-card-text>
			<v-form @submit.prevent="" v-model="form.isValid">
				<v-text-field v-model="form.url"
							  :rules="rules"
							  label="Введите ссылку или текст"
							  required
							  outlined
							  maxlength="4096"/>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
	import {debounce} from "lodash";
	import Config from "@/config";

	export default {
		name: "home-card",
		data: () => ({
			error: null,
			loading: false,
			form: {
				url: "",
				isValid: false
			},
			result: {
				value: "",
			},
			rules: [
				v => /https\:\/\/.+/.test(v) || 'Введите корректный URL',
			],
		}),
		computed: {
			isFormValid() {
				return true;
			}
		},
		methods: {
			submitDebounced: debounce(function () {

				// check link is correct
				if (!this.isFormValid) return;

				this.loading = true;
				this.error = null;
				this.$store.dispatch('post', {
					action: 'LinkController',
					params: this.form.link
				}).then((res) => {
					console.log("LINK res", res);
					this.result.link.value = res.page.LinkController.link;
				}).catch((error) => {
					this.error = error?.message || error || 'Не получилось';
				}).finally(() => {
					this.loading = false;
				});

			}, Config.DEBOUNCE_INPUT_TIMEOUT),
		}
	}
</script>

<style lang="scss">
</style>