<template>
	<v-card class="d-flex flex-column home-link">
		<v-card-title>
			Сократить ссылку
		</v-card-title>
		<v-card-text>
			<v-form @submit.prevent="submit" v-model="form.isValid">
				<v-text-field v-model="form.url"
							  :rules="rules"
							  label="Введите длинную ссылку"
							  required
							  outlined
							  clearable
							  maxlength="4096"
							  :disabled="loading"
							  @keyup="submitDebounced"/>

				<transition name="fade" mode="out-in">
					<v-progress-linear v-if="loading"
									   color="red lighten-2" class="mt-6"
									   buffer-value="0"
									   stream reverse/>
					<div v-else-if="result" class="d-flex align-center">
						<v-chip label link x-large class="justify-center w100" dark
								:color="resultColor"
								@click="onCopy">

							<span v-if="isCopied">Скопировано в буфер!</span>
							<template v-else>
								{{result}}
								<v-icon right class="ml-4">
									mdi-content-copy
								</v-icon>
							</template>

						</v-chip>
						<v-btn x-large text class="ml-4" @click="onClose">
							<v-icon>
								mdi-close
							</v-icon>
						</v-btn>
					</div>
				</transition>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
	import {debounce} from "lodash";
	import {isLinkValid} from "@/utils/utils";
	import Config from "@/config";

	export default {
		name: "home-card",
		data: () => ({
			error: null,
			loading: false,
			form: {
				url: "",
				isValid: false
			},
			result: null,
			isCopied: false,
			rules: [
				v => {
					v = v?.replace(/ +/, "");
					if ( !v.match(/^https?\:\/\/.*/i) ) v = "https://" + v;
					return isLinkValid(v) || 'Введите корректный URL';
				}
			],
		}),
		computed: {
			resultColor() {
				return this.isCopied ? "green" : "indigo";
			}
		},
		methods: {
			onClose() {
				this.result = null;
				this.form.url = null;
			},
			submitDebounced: debounce(function () {
				this.submit();
			}, Config.DEBOUNCE_INPUT_TIMEOUT),
			submit() {
				// check link is correct
				//if (!this.isFormValid) return;
				if (!this.form.isValid) return;

				//console.log("Submitting!..");

				this.loading = true;
				this.error = null;
				this.$store.dispatch('post', {
					action: 'LinkController',
					params: this.form
				}).then((res) => {
					this.result = res.page.LinkController.link;
				}).catch((error) => {
					this.error = error?.message || error || 'Не получилось';
				}).finally(() => {
					this.loading = false;
				});
			},
			onCopy() {
				let textarea = document.createElement('textarea');
				textarea.textContent = this.result;
				document.body.appendChild(textarea);
				const selection = document.getSelection();
				const range = document.createRange();
				range.selectNode(textarea);
				selection.removeAllRanges();
				selection.addRange(range);
				//console.log('copy success', document.execCommand('copy'));
				document.execCommand('copy');
				selection.removeAllRanges();
				document.body.removeChild(textarea);

				this.isCopied = true;
				setTimeout(() => {
					this.isCopied = false;
				}, 2500);
			},
		}
	}
</script>

<style lang="scss">
	.home-link {
		.v-btn {
			min-width: unset;
		}
	}
</style>